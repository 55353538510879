import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { history } from "./history"
const LoginWrapper = React.lazy(() =>
  import("./containers/Login/LoginWrapper")
)
const UpdatePasswordWrapper = React.lazy(() =>
  import("./containers/UpdatePassword/UpdatePasswordWrapper")
)
const ForgotPasswordWrapper = React.lazy(() =>
  import("./containers/ForgotPassword/ForgotPasswordWrapper")
)
const AccessDeniedWrapper = React.lazy(() =>
  import("./containers/AccessDenied/AccessDeniedWrapper")
)

const RequestMembership = React.lazy(() =>
  import("./containers/RequestMembership/RequestMembership")
)

const App: React.SFC<any> = () => {
  return (
    <Router history={history}>
      <React.Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/login" component={LoginWrapper} />
          <Route
            exact
            path="/forgot-password"
            component={ForgotPasswordWrapper}
          />
          <Route
            exact
            path="/update-password/:otp"
            component={UpdatePasswordWrapper}
          />
          <Route
            exact
            path="/error/access-denied"
            component={AccessDeniedWrapper}
          />
          <Route
            exact
            path="/request-membership"
            component={RequestMembership}
          />
          <Route
            path="/create-password/:otp"
            component={UpdatePasswordWrapper}
          />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App
